import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable } from 'mobx';

export enum UnitSystem {
  // imperial - inches, feet, yards, miles, etc.
  IMPERIAL = 'imperial',
  // metric - millimeters, centimeters, meters, kilometers, etc.
  METRIC = 'metric',
}

/**
 * A simple store for persisting observable data in the local store.
 * Anything added to LocalPersistenceStore.data and set via setData() will be automatically persisted.
 *
 * Access this through getRootStore().localPersistenceStore.
 */
export class LocalPersistenceStore {
  static readonly KEY = 'LocalPersistenceStore.data';

  readonly data: {
    structureExpanded: boolean;

    preferredUnit: UnitSystem;
  } = {
      structureExpanded: false,
      preferredUnit: UnitSystem.IMPERIAL,
    };

  constructor(public readonly root: RootStore) {}

  init() {
    const persistedData = JSON.parse(
      localStorage.getItem(LocalPersistenceStore.KEY) ?? '{}',
    );
    Object.keys(persistedData).forEach((key) => {
      this.data[key] = persistedData[key];
    });
    makeAutoObservable(this);
  }

  setData(value: Partial<typeof this.data>) {
    Object.assign(this.data, value);
    localStorage.setItem(LocalPersistenceStore.KEY, JSON.stringify(this.data));
  }
}

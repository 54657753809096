import { FieldDefinition } from '@/FieldDefinitions/types';
import {
  EditInventoryEvent,
  EditSample,
  FieldHash,
  InventoryEvent,
  Sample,
} from '@/Samples/types';
import {
  CategorizedFieldDefinitionsMap,
  ProcessedInventoryEntry
} from '../components/types';
import { InventoryStore } from './inventoryStore';
import { FieldTypeValues } from '../components/consts';

export class InventoryStoreHelper {
  static convertNameKeysToFieldIds(
    fields: FieldHash,
    fieldDefinitions: FieldDefinition[],
  ): InventoryEvent['inventory_event_fields'] {
    const relevantDefs = fieldDefinitions.filter(
      (fieldDefinition) => fields[fieldDefinition.name] !== undefined,
    );
    return Object.fromEntries(
      relevantDefs.map((def) => [def.id, fields[def.name]]),
    );
  }

  static convertToInventorySample(
    inventoryEntry: Partial<ProcessedInventoryEntry>,
    inventory_event_field_definitions: InventoryStore['inventory_field_definitions']['Event'],
  ): EditSample {
    return {
      ...inventoryEntry,
      inventory_sample_fields_orig: inventoryEntry.inventory_sample_fields,
      inventory_events: (inventoryEntry.inventory_events || []).map((event) =>
        InventoryStoreHelper.convertEntryToInventoryEvent(
          event,
          inventoryEntry.id,
          inventory_event_field_definitions,
        ),
      ),
      inventory_sample_fields: inventoryEntry.inventory_sample_fields,
      inventory_event_fields: inventoryEntry.inventory_event_fields,
      batch_id: inventoryEntry.batch.id,
      batch_name: inventoryEntry.batch.name,
      created_at: inventoryEntry.sample_created_date,
      created_by_user_full_name:
        inventoryEntry.sample_created_by_user_full_name,
      modified_at: inventoryEntry.sample_updated_date,
      modified_by_user_full_name:
        inventoryEntry.sample_updated_by_user_full_name,
      name: inventoryEntry.name,
      units: inventoryEntry.units,
      location: inventoryEntry.location,
      is_single_use: inventoryEntry.is_single_use,
    };
  }

  static convertEntryToInventoryEvent(
    val: ProcessedInventoryEntry['inventory_events'][number],
    sample_id: number,
    inventory_event_field_definitions: InventoryStore['inventory_field_definitions']['Sample'],
  ): EditInventoryEvent {
    return {
      ...val,
      id: val.event_id,
      created_at: val.event_created_date,
      modified_at: val.event_modified_date,
      created_by_user_full_name: val.event_created_by_user_full_name,
      modified_by_user_full_name: val.event_modified_by_user_full_name,
      inventory_sample_id: sample_id,
      field_definitions: inventory_event_field_definitions,
      inventory_event_fields: val.inventory_event_fields,
    };
  }

  static combineInventoryFields(
    custom_field_definitions: InventoryStore['custom_field_definitions'],
    default_field_definitions: InventoryStore['inventory_field_definitions'],
  ): CategorizedFieldDefinitionsMap {
    return Object.fromEntries(
      FieldTypeValues.map((fieldType) => [
        fieldType,
        [
          ...custom_field_definitions[fieldType],
          ...default_field_definitions[fieldType],
        ],
      ]),
    ) as CategorizedFieldDefinitionsMap;
  }

  static processInventorySearchEntrySampleFields(
    sample: Sample,
    inventory_sample_field_definitions: FieldDefinition[],
  ) {
    return {
      inventory_sample_fields: InventoryStoreHelper.convertNameKeysToFieldIds(
        sample.inventory_sample_fields,
        inventory_sample_field_definitions,
      ),
    };
  }

  static processInventorySearchEntryEventFields(
    event: InventoryEvent,
    inventory_event_field_definitions: FieldDefinition[],
  ) {
    return {
      inventory_event_fields: event
        ? InventoryStoreHelper.convertNameKeysToFieldIds(
          event.inventory_event_fields,
          inventory_event_field_definitions,
        )
        : undefined,
    };
  }

  static processInventorySearchEvent(event: InventoryEvent) {
    return {
      event_id: event.id,
      event_created_by_user_id: event.created_by_user_id,
      event_created_by_user_full_name: event.created_by_user_full_name,
      event_created_date: event.created_at,
      event_modified_date: event.modified_at,
      event_modified_by_user_full_name: event.modified_by_user_full_name,
      inventory_event_fields_name_keyed: event.inventory_event_fields,
    };
  }

  static processSample(sample: Sample) {
    return {
      ...sample.batch,
      ...sample,
      // gotta make sure these don't get overridden by batch or event
      name: sample.name,
      id: sample.id,
      batch_id: sample.batch.id,
      batch_name: sample.batch.name,
      sample_created_date: sample.created_at,
      sample_created_by_user_full_name: sample.created_by_user_full_name,
      sample_updated_by_user_full_name: sample.modified_by_user_full_name,
      sample_updated_date: sample.modified_at,
      inventory_sample_fields_name_keyed: sample.inventory_sample_fields,
    };
  }

  static processInventorySearchEntrySample(
    sample: Sample,
    inventory_sample_field_definitions: FieldDefinition[],
    inventory_event_field_definitions: FieldDefinition[],
  ) {
    return {
      ...InventoryStoreHelper.processSample(sample),
      ...InventoryStoreHelper.processInventorySearchEntrySampleFields(
        sample,
        inventory_sample_field_definitions,
      ),
      ...InventoryStoreHelper.processInventorySearchEntryEventFields(
        sample.inventory_events[0],
        inventory_event_field_definitions,
      ),
    };
  }

  static processEventsFromSearchEntrySample(
    sample: Sample,
    inventory_event_field_definitions: FieldDefinition[],
  ) {
    return {
      inventory_events: sample.inventory_events.map((event) => ({
        ...InventoryStoreHelper.processInventorySearchEvent(event),
        ...InventoryStoreHelper.processInventorySearchEntryEventFields(
          event,
          inventory_event_field_definitions,
        ),
      })),
    };
  }

  static processInventorySearchEntries(
    samples: Sample[],
    inventory_sample_field_definitions: FieldDefinition[],
    inventory_event_field_definitions: FieldDefinition[],
  ): ProcessedInventoryEntry[] {
    const processedEntries = samples.map((sample): ProcessedInventoryEntry => {
      return {
        ...InventoryStoreHelper.processInventorySearchEntrySample(
          sample,
          inventory_sample_field_definitions,
          inventory_event_field_definitions,
        ),
        ...InventoryStoreHelper.processEventsFromSearchEntrySample(
          sample,
          inventory_event_field_definitions,
        ),
      };
    });
    return processedEntries;
  }
}

import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import { EditInventoryEvent, EditSample, InventoryEvent, LocationValue, Sample } from '../types';
import axios, { Method } from 'axios';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { cancelBroadcastError } from '@/ErrorReporting/subscribe';
import { keysAsNumbers } from '@/shared/utils/objectKeys';
import { term } from '@/shared/utils/stringUtils';
import { currentAmountForSample, locationForEvent, setEventCreditDebit } from './sampleDataStore';
import { AnyObject } from '@/types';
import { deepClone } from '@/Annotator/data/utils';
import { FieldDefinitionUtils } from '@/FieldDefinitions/shared/fieldDefinitionUtils';

export type CurrentEditType =
  'NEW_SAMPLE'
  | 'EDIT_SAMPLE'
  | 'EDIT_SAMPLE_WITH_FIRST_EVENT'
  | 'EDIT_SINGLE_USE_SAMPLE'
  | 'NEW_EVENT'
  | 'EDIT_EVENT';

type FormState = {
  data: {
    initialValue: number;
    originalLocation: LocationValue | null;
    creditOrDebit: 'credit' | 'debit';
    creditOrDebitValue: number;
    createSampleFromDebit: boolean;
  };
  errors: AnyObject;
};

export class EditInventoryStore {
  private currentlyEditingEvent: EditInventoryEvent = null;
  private currentlyEditingSample: EditSample = null;
  private currentEditType: CurrentEditType = null;

  private currentlyEditingEventForSampleFromDebit: EditInventoryEvent = null;

  currentlyRestoringSample = false;
  disableSampleIdField = false;

  formState: FormState = {
    data: {
      initialValue: 0,
      originalLocation: null,
      creditOrDebit: 'debit',
      creditOrDebitValue: 0,
      createSampleFromDebit: false,
    },
    errors: {},
  };

  get currentlyEditingValue() {
    let parentEvent = null;
    if (this.currentlyEditingSample || this.currentlyEditingEvent) {
      let sample = this.currentlyEditingSample;
      let event = this.currentlyEditingEvent;
      if (event && sample) {
        event = sample.inventory_events.find(e => e.id === event.id) ?? event;
      }
      if (this.formState.data.createSampleFromDebit) {
        event = this.currentlyEditingEventForSampleFromDebit;
        parentEvent = this.currentlyEditingEvent;
        sample = {
          ...sample,
          inventory_events: [event],
        };
      }
      return {
        type: this.currentEditType,
        sample,
        event,
        parentEvent,
      };
    }
    return null;
  }

  get currentlyEditingInventoryBatches() {
    return this.currentlyEditingValue?.sample ? [this.currentlyEditingValue.sample.batch] : [];
  }

  get currentLocation() {
    return this.currentlyEditingValue ? locationForEvent(this.currentlyEditingValue.event) : null;
  }

  get currentEditText() {
    return {
      NEW_SAMPLE: 'Create a New Sample',
      EDIT_SAMPLE: 'Edit Sample',
      EDIT_SAMPLE_WITH_FIRST_EVENT: 'Edit Sample',
      EDIT_SINGLE_USE_SAMPLE: 'Edit Single Use Sample',
      NEW_EVENT: 'Add Event',
      EDIT_EVENT: 'Edit Event',
    }[this.currentEditType];
  }

  get showEventFields() {
    const { currentEditType } = this;
    return ['NEW_EVENT', 'EDIT_EVENT', 'NEW_SAMPLE', 'EDIT_SINGLE_USE_SAMPLE', 'EDIT_SAMPLE_WITH_FIRST_EVENT'].includes(currentEditType);
  }

  get showSampleFields() {
    const { currentEditType } = this;
    return ['NEW_SAMPLE', 'EDIT_SAMPLE', 'EDIT_SINGLE_USE_SAMPLE', 'EDIT_SAMPLE_WITH_FIRST_EVENT'].includes(currentEditType);
  }

  get unitsForEvent() {
    const { currentlyEditingSample } = this;
    return currentlyEditingSample?.units || '';
  }

  get inventory_event_field_definitions() {
    return this.root.sampleDataStore.inventory_event_field_definitions;
  }

  get inventory_sample_field_definitions() {
    return this.root.sampleDataStore.inventory_sample_field_definitions;
  }

  get samples() {
    return this.root.sampleDataStore.samples;
  }

  get sample() {
    return this.currentlyEditingSample;
  }

  get needsUpdatedLocation() {
    const { sample, currentLocation } = this;
    return (['EDIT_SINGLE_USE_SAMPLE', 'NEW_EVENT'].includes(this.currentEditType) &&
      sample.depleted &&
      (currentLocation?.value === this.formState.data.originalLocation?.value || !currentLocation?.value));
  }

  get sampleRelocation() {
    const result: { oldLocation?: LocationValue, newLocation?: LocationValue } = {};

    if (this.currentlyEditingSample && this.formState.data.createSampleFromDebit) {
      const sample = this.currentlyEditingSample;
      const event = this.currentlyEditingEvent;
      const eventLocation = locationForEvent(event);
      if (sample.location && eventLocation &&
        (sample.location.id !== eventLocation?.id || eventLocation.position !== sample.location.position)) {
        result.oldLocation = sample.location;
        result.newLocation = eventLocation;
      }
    }
    return result;
  }

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });

    reaction(() => this.formState.data.createSampleFromDebit, value => {
      if (value) {
        runInAction(() => {
          this.formState.data.initialValue = this.formState.data.creditOrDebitValue;
        });
      }
    });
  }

  private handleEdit(type: CurrentEditType, val: { sample?: EditSample, event?: EditInventoryEvent }) {
    this.currentEditType = type;
    this.currentlyEditingSample = val.sample;
    this.currentlyEditingEvent = val.event;

    // by default, we don't disable the sample id field
    this.disableSampleIdField = false;
    if (type === 'EDIT_SAMPLE') {
      // however, if we're editing a sample AND the sample has a value for the sample id field, we need to disable the sample id field
      // to prevent further editing. If it's blank, this means the field definition was made into a sample id after the sample was created.
      const sampleFieldDefinitions = this.root.sampleDataStore.inventory_sample_field_definitions;
      const { sample } = this;
      if (sample.id) {
        const sampleId = sampleFieldDefinitions.find(
          (fieldDefinition) => fieldDefinition.is_sample_id,
        )?.id;
        this.disableSampleIdField = !!sample.inventory_sample_fields?.[sampleId];
      }
    }
  }

  handleCancelEdit() {
    this.currentEditType = null;
    this.currentlyEditingSample = null;
    this.currentlyEditingEvent = null;
    this.currentlyEditingEventForSampleFromDebit = null;
    this.currentlyRestoringSample = false;
    this.formState.data.createSampleFromDebit = false;
  }

  loadSamples() {
    this.root.sampleDataStore.loadSamples();
  }

  // create and edit events
  handleCreateEvent(sample: EditSample) {
    const { inventory_event_field_definitions } = this.root.sampleDataStore;
    const fieldDefinitionLocation = inventory_event_field_definitions.find(
      (field) => field.name === 'Location',
    );
    const location = sample?.location as LocationValue;

    Object.assign(this.formState.data, {
      initialValue: 0,
      originalLocation: null,
      creditOrDebit: 'debit',
      creditOrDebitValue: 0,
      createSampleFromDebit: false,
    });

    const createEvent = (withLocation = false) => {
      const result: EditInventoryEvent = {
        field_definitions: inventory_event_field_definitions,
        inventory_sample_id: sample.id,
        inventory_event_fields: {},
        inventory_event_fields_name_keyed: {},
      };
      if (withLocation && fieldDefinitionLocation && location) {
        result.inventory_event_fields[fieldDefinitionLocation.id] =
          result.inventory_event_fields_name_keyed.Location = location;
      }
      return result;
    };

    const event = createEvent(true);
    const newSample = deepClone(sample);
    this.inventory_sample_field_definitions.forEach(fieldDefinition => {
      if (fieldDefinition.unique || fieldDefinition.is_sample_id) {
        delete newSample.inventory_sample_fields[fieldDefinition.id];
      }
    });

    this.handleEdit('NEW_EVENT', { event, sample: newSample });
    this.currentlyEditingEventForSampleFromDebit = createEvent(false);
    return event;
  }

  handleEditEvent(event: InventoryEvent, sample?: EditSample) {
    const Credit = event?.inventory_event_fields_name_keyed?.Credit ?? 0;
    const Debit = event?.inventory_event_fields_name_keyed?.Debit ?? 0;

    Object.assign(this.formState.data, {
      originalLocation: locationForEvent(event),
      creditOrDebit: Credit > 0 ? 'credit' : 'debit',
      creditOrDebitValue: Credit || Debit || 0,
    });

    event = deepClone(event);
    sample = sample ?? this.samples.find(sample => sample.id == event.inventory_sample_id);
    this.handleEdit('EDIT_EVENT', { event, sample });
  }

  // samples
  handleCreateSample() {
    Object.assign(this.formState.data, {
      initialValue: 0,
      originalLocation: null,
      creditOrDebit: 'debit',
      creditOrDebitValue: 0,
      createSampleFromDebit: false,
    });

    // initialize a new empty sample for editing, which will cause the dialog to show
    const sample = {
      field_definitions: this.inventory_sample_field_definitions,
      inventory_events: [
        {
          field_definitions: this.inventory_event_field_definitions,
          inventory_event_fields: {},
        } as InventoryEvent,
      ],
    };
    this.handleEdit('NEW_SAMPLE', {
      event: sample.inventory_events[0],
      sample,
    });
  }

  handleEditSample(sample: EditSample, showEvents = false) {
    const event = sample.inventory_events?.[0];
    const Credit = event?.inventory_event_fields_name_keyed?.Credit ?? 0;
    const Debit = event?.inventory_event_fields_name_keyed?.Debit ?? 0;

    const originalLocation = event ? locationForEvent(event) : null;
    const initialValue = currentAmountForSample(sample) ?? 0;

    Object.assign(this.formState.data, {
      originalLocation,
      creditOrDebit: Credit > 0 ? 'credit' : 'debit',
      creditOrDebitValue: Credit || Debit || 0,
      initialValue,
    });

    const clonedSample = deepClone<EditSample>(
      sample ?? { inventory_events: [] },
    );
    // set the first event if there is none for a single use sample
    if (
      sample.is_single_use &&
      !sample.inventory_events[0]?.inventory_event_fields
    ) {
      // eslint-disable-next-line dot-notation
      clonedSample['inventory_events'] = [
        {
          field_definitions: this.inventory_event_field_definitions,
          inventory_event_fields: {},
        } as InventoryEvent,
      ];
    }

    if (clonedSample.is_single_use) {
      this.handleEdit('EDIT_SINGLE_USE_SAMPLE', { sample: clonedSample, event: clonedSample.inventory_events[0] });
    } else if (showEvents) {
      this.handleEdit('EDIT_SAMPLE_WITH_FIRST_EVENT', { sample: clonedSample, event: clonedSample.inventory_events[0] });
    } else {
      this.handleEdit('EDIT_SAMPLE', { sample: clonedSample });
    }
  }

  handleEditLocationForRestoredSample(sample: EditSample) {
    sample.depleted = true;
    this.currentlyRestoringSample = true;
    if (sample && sample.is_single_use) {
      this.handleEditSample(sample as Sample);
    } else {
      this.handleCreateEvent(sample);
    }
  }

  handleError(error) {
    if (axios.isAxiosError(error)) {
      cancelBroadcastError(error);
      ModalUtils.showModal(
        error.response?.data?.[0] ?? 'An unknown error occurred',
        {
          title: 'Error',
          noCancelOption: true,
        },
      );
    }
  }

  addEventFieldsToFormData = (
    event: Partial<InventoryEvent>,
    formData: FormData,
    skipIds: Array<number> = [],
    prefix = 'inventory_event',
  ) => {
    const data = { inventory_event: { ...toJS(event) } };

    const inventory_event_fields =
      data.inventory_event?.inventory_event_fields ?? {};

    const keys = keysAsNumbers(inventory_event_fields).filter(
      (key) => !skipIds.includes(key),
    );
    if (event.id) {
      formData.append(`${prefix}[id]`, '' + event.id);
    }
    for (let i = 0; i < keys.length; i++) {
      const fieldId = keys[i];
      formData.append(
        `${prefix}[inventory_event_fields_attributes][${i}][field_definition_id]`,
        '' + fieldId,
      );
      let fieldValue = inventory_event_fields[fieldId] ?? '';
      if (typeof fieldValue === 'object') {
        if (fieldValue.uploaded_file_id) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const fieldFormData = (fieldValue as any).formData;
          fieldValue = fieldValue.uploaded_file_id;
          if (fieldFormData) {
            for (const pair of fieldFormData.entries()) {
              if (pair[0] === 'qqfile') {
                formData.append(pair[0], pair[1]);
              }
            }
          }
        } else if (
          fieldValue.id &&
          fieldValue.value &&
          Object.prototype.hasOwnProperty.call(fieldValue, 'position')
        ) {
          // location: could be in organized or unorganized box
          fieldValue = `${fieldValue.id},${fieldValue.position ?? ''}`;
        }
      }
      formData.append(
        `${prefix}[inventory_event_fields_attributes][${i}][value]`,
        '' + fieldValue,
      );
    }
    if (!keys.length) {
      formData.append(`${prefix}[inventory_event_fields_attributes][0]`, '');
    }
  };

  handleSubmitEvent() {
    if (this.formState.data.createSampleFromDebit) {
      this.handleSubmitSampleFromDebit();
      return;
    }
    const { routerStore } = this.root;
    const { event } = this.currentlyEditingValue;
    const { vaultId, moleculeId } = routerStore.extractFromPattern(
      '/vaults/:vaultId/molecules/:moleculeId',
    ) as { vaultId?: number; moleculeId?: number };

    const url = `/vaults/${vaultId}/molecules/${moleculeId}/inventory_samples/${event.inventory_sample_id}`;
    const method: Method = 'put';
    const formData = new FormData();

    const sample = this.samples.find(
      (sample) => sample.id === event.inventory_sample_id,
    );
    const skipIds = FieldDefinitionUtils.getSkipIdsForEvent(sample, event);

    this.addEventFieldsToFormData(
      event,
      formData,
      skipIds,
      'inventory_sample[inventory_events_attributes][0]',
    );

    // if we're submitting an event to resolve a depleted sample restoration, set depleted to false
    if (this.currentlyRestoringSample) {
      formData.append('inventory_sample[depleted]', 'false');
    }

    axios({
      url,
      method,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(() => {
        this.handleCancelEdit();
        this.loadSamples();
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  handleSubmit() {
    switch (this.currentEditType) {
      case 'NEW_EVENT':
      case 'EDIT_EVENT':
        this.handleSubmitEvent();
        break;
      case 'NEW_SAMPLE':
      case 'EDIT_SAMPLE':
      case 'EDIT_SINGLE_USE_SAMPLE':
      case 'EDIT_SAMPLE_WITH_FIRST_EVENT':
        this.handleSubmitSample();
        break;
    }
  }

  handleDelete() {
    switch (this.currentEditType) {
      case 'EDIT_EVENT':
        this.handleDeleteEvent();
        break;
      case 'EDIT_SAMPLE':
      case 'EDIT_SINGLE_USE_SAMPLE':
      case 'EDIT_SAMPLE_WITH_FIRST_EVENT':
        this.handleDeleteSample();
        break;
    }
  }

  handleSubmitSample = async (
    sample: EditSample = null,
    sampleForResolvingLocationConflict: Sample = null,
  ) => {
    sample = sample ?? this.currentlyEditingValue?.sample;
    const sampleId = sample.id;
    const { routerStore } = this.root;

    const { vaultId, moleculeId } = routerStore.extractFromPattern(
      '/vaults/:vaultId/molecules/:moleculeId',
    ) as { vaultId?: number; moleculeId?: number };

    let url = `/vaults/${vaultId}/molecules/${moleculeId}/inventory_samples`;
    if (sampleId) {
      url += `/${sampleId}`;
    }
    if (this.formState.data.createSampleFromDebit) {
      url += `/${sample.id}/create_sample_from_debit`;
    }

    const formData = new FormData();
    const { inventory_sample_fields = {} } = toJS(sample);

    ['id', 'name', 'units', 'batch_id', 'depleted'].forEach((key) => {
      if (sample[key] !== undefined) {
        formData.append(`inventory_sample[${key}]`, sample[key]);
      }
    });

    keysAsNumbers(inventory_sample_fields).forEach((fieldId, i) => {
      formData.append(
        `inventory_sample[inventory_sample_fields_attributes][${i}][field_definition_id]`,
        '' + fieldId,
      );
      let fieldValue = inventory_sample_fields[fieldId] ?? '';
      if (typeof fieldValue === 'object' && fieldValue.uploaded_file_id) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fieldFormData = (fieldValue as any).formData;
        fieldValue = fieldValue.uploaded_file_id;
        if (fieldFormData) {
          for (const pair of fieldFormData.entries()) {
            if (pair[0] === 'qqfile') {
              formData.append(pair[0], pair[1]);
            }
          }
        }
      }

      formData.append(
        `inventory_sample[inventory_sample_fields_attributes][${i}][value]`,
        '' + fieldValue,
      );
    });

    const event = sample.inventory_events?.[0];
    if (
      (sample.inventory_events?.length && !sample.id) ||
      sample.is_single_use
    ) {
      this.addEventFieldsToFormData(
        event,
        formData,
        [], // do not skip the location field for the new event
        'inventory_sample[inventory_events_attributes][0]',
      );
    }

    const method = sampleId ? 'put' : 'post';

    // if we're updating a sample to resolve a depleted sample restoration, set depleted to false
    if (this.currentlyRestoringSample) {
      formData.append('inventory_sample[depleted]', 'false');
    }
    try {
      await axios({
        url,
        method,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      this.handleCancelEdit();

      await this.loadSamples();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          cancelBroadcastError(error);

          this.handleEditLocationForRestoredSample(sampleForResolvingLocationConflict);
          return;
        }
      }
      this.handleError(error);
    }
  };

  handleSubmitSampleFromDebit = async () => {
    const parentEvent = this.currentlyEditingEvent;
    const childEvent = this.currentlyEditingEventForSampleFromDebit;

    setEventCreditDebit(childEvent, this.formState.data.initialValue);
    setEventCreditDebit(parentEvent, -this.formState.data.initialValue);

    const sample = this.currentlyEditingSample;
    const { routerStore } = this.root;

    const { vaultId, moleculeId } = routerStore.extractFromPattern(
      '/vaults/:vaultId/molecules/:moleculeId',
    ) as { vaultId?: number; moleculeId?: number };

    const url = `/vaults/${vaultId}/molecules/${moleculeId}/inventory_samples/${sample.id}/create_sample_from_debit`;

    const formData = new FormData();
    const { inventory_sample_fields = {} } = toJS(this.currentlyEditingSample);

    // add the sample attributes
    const sampleAttributePrefix = 'inventory_sample[inventory_events_attributes][0][child_sample_attributes]';
    ['name', 'units', 'batch_id', 'depleted'].forEach((key) => {
      if (sample[key] !== undefined) {
        formData.append(`${sampleAttributePrefix}[${key}]`, sample[key]);
      }
    });

    keysAsNumbers(inventory_sample_fields).forEach((fieldId, i) => {
      const attributePrefix = `${sampleAttributePrefix}[inventory_sample_fields_attributes][${i}]`;
      formData.append(`${attributePrefix}[field_definition_id]`, '' + fieldId);
      let fieldValue = inventory_sample_fields[fieldId] ?? '';
      if (typeof fieldValue === 'object' && fieldValue.uploaded_file_id) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fieldFormData = (fieldValue as any).formData;
        fieldValue = fieldValue.uploaded_file_id;
        if (fieldFormData) {
          for (const pair of fieldFormData.entries()) {
            if (pair[0] === 'qqfile') {
              formData.append(pair[0], pair[1]);
            }
          }
        }
      }

      formData.append(`${attributePrefix}[value]`, '' + fieldValue);
    });

    // add the parent event fields. We should skip the location if not modified.
    this.addEventFieldsToFormData(
      parentEvent,
      formData,
      FieldDefinitionUtils.getSkipIdsForEvent(sample, parentEvent),
      'inventory_sample[inventory_events_attributes][0]',
    );

    // add the child event fields for the new event, but don't skip location if supplied
    const sampleWithoutLocation = { ...sample, location: null };
    this.addEventFieldsToFormData(
      childEvent,
      formData,
      FieldDefinitionUtils.getSkipIdsForEvent(sampleWithoutLocation, childEvent),
      'inventory_sample[inventory_events_attributes][0][child_sample_attributes][inventory_events_attributes][0]',
    );

    const method = 'put';

    try {
      await axios({
        url,
        method,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      this.handleCancelEdit();

      await this.loadSamples();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          cancelBroadcastError(error);
          this.handleEditLocationForRestoredSample(sample);
          return;
        }
      }
      this.handleError(error);
    }
  };

  async handleDeleteSample() {
    const { currentlyEditingSample } = this;
    const { routerStore } = this.root;
    const { vaultId } = routerStore.extractFromPattern('/vaults/:vaultId') ?? {};
    const moleculeId = routerStore.extractFromPattern('/vaults/:vaultId/molecules/:moleculeId')?.moleculeId ?? currentlyEditingSample.batch?.molecule_id;

    if (
      await ModalUtils.confirmDelete(
        `${term('sample')} "${currentlyEditingSample.name}"`,
      )
    ) {
      const url = `/vaults/${vaultId}/molecules/${moleculeId}/inventory_samples/${currentlyEditingSample.id}`;
      await axios.delete(url);

      this.handleCancelEdit();
      this.root.sampleDataStore.loadSamples();
    }
  }

  async handleDeleteEvent() {
    const event = this.currentlyEditingEvent;
    const { routerStore } = this.root;
    const { vaultId, moleculeId } = routerStore.extractFromPattern(
      '/vaults/:vaultId/molecules/:moleculeId',
    ) as { vaultId?: number; moleculeId?: number };

    if (await ModalUtils.confirmDelete(`this ${term('event', true)}`)) {
      const url = `/vaults/${vaultId}/molecules/${moleculeId}/inventory_samples/${event.inventory_sample_id}`;
      const method: Method = 'put';
      const formData = new FormData();
      formData.append(
        'inventory_sample[inventory_events_attributes][0][id]',
        `${event.id}`,
      );
      formData.append(
        'inventory_sample[inventory_events_attributes][0][_destroy]',
        '1',
      );
      try {
        await axios({
          url,
          method,
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        this.handleCancelEdit();
        this.root.sampleDataStore.loadSamples();
      } catch (error) {
        this.handleError(error);
      }
    }
  }
}
